/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import axios from 'axios';
import useStore from 'state/state';
import Button from 'components/elements/Button/Button';
import UserForm from 'components/elements/UserForm/UserForm';

import styles from './NewUserPage.module.scss';

const tokenDataState = (state) => state.tokenData;
const isErrorState = (state) => state.setIsError;
const setListOfUsersState = (state) => [
  state.listOfUsers,
  state.setListOfUsers,
];
const listOfGroupsState = (state) => [
  state.listOfGroups,
  state.setListOfGroups,
];

const NewUserPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const tokenData = useStore(tokenDataState);
  const setIsError = useStore(isErrorState);
  const [listOfUsers, setListOfUsers] = useStore(setListOfUsersState);
  const [listOfGroups, setListOfGroups] = useStore(listOfGroupsState);
  const [userCreated, setUserCreated] = useState(null);
  const history = useHistory();

  const config = {
    headers: {
      Authorization: `Bearer ${tokenData}`,
      'x-api-key': process.env.API_KEY,
      'X-Origin': window.origin,
    },
  };

  const fetchGroups = () => {
    axios
      .get(`${process.env.URL}/group/list`, config)
      .then((res) => {
        const groups = [];
        res.data.map((gr) => {
          const display_name = gr.group_name.split(' - ');
          return groups.push({ ...gr, display_name: display_name[1] });
        });
        setListOfGroups(groups);
      })
      .catch((err) => {
        console.log(err);
        setIsError('Something went wrong, user groups was not collected.');
      });
  };

  useEffect(() => {
    if ((!listOfGroups || !listOfGroups?.length) && tokenData) {
      fetchGroups();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenData]);

  const onSend = (obj) => {
    setIsLoading(true);

    axios
      .post(`${process.env.URL}/user/register`, obj, config)
      .then((res) => {
        if (listOfUsers) {
          const newUserObj = {
            ...obj,
            userid: String(res.data.userid),
            user_groups: res.data.user_groups,
            admin_user: '0',
          };
          const newList = [...listOfUsers];
          newList.push(newUserObj);
          setListOfUsers(newList);
        }
        setUserCreated(obj);
        setIsLoading(false);
        setTimeout(() => {
          history.push('/');
        }, 3000);
      })
      .catch((error) => {
        console.log('err', error);
        const errorMessage = error.response.data.error.message;
        if (errorMessage === 'Invalid email') {
          setIsError('Invalid e-mail address.');
        } else if (errorMessage === 'User account already exists') {
          setIsError('User with this email already exists.');
        } else {
          setIsError('Something went wrong.');
        }
        setIsLoading(false);
      });
  };

  const onCancel = () => {
    history.push('/');
  };

  if (!tokenData) {
    return (
      <div className={styles.wrapper}>
        <Button
          onClick={() => {
            history.push('/');
          }}
        >
          Take me to login page
        </Button>
      </div>
    );
  }

  if (userCreated) {
    return (
      <div className={styles.wrapper}>
        <div className={styles.userCreatedContainer}>
          <p>
            {`An e-mail invitation has been sent to ${userCreated.first_name} ${userCreated.last_name}.`}
          </p>
          <p>You will now be redirected back...</p>
          <Link to="/">
            <Button onClick={() => {}}>Take me back now</Button>
          </Link>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.contentWrapper}>
        <h3>Create a new user</h3>
        <UserForm
          sendUserFunc={onSend}
          cancelFunc={onCancel}
          isLoading={isLoading}
          listOfGroups={listOfGroups}
          resetPasswordFunc={() => {}}
          isCreatingNewUser
        />
      </div>
    </div>
  );
};

export default NewUserPage;
