/* eslint-disable camelcase */
import React, { useState } from 'react';
import { shape, string, func, bool, arrayOf } from 'prop-types';
import useStore from 'state/state';
import Button from '../Button/Button';

import styles from './UserForm.module.scss';

const normalFieldColor = '#ededeb';
const errorFieldColor = '#c63d11';

const isErrorState = (state) => state.setIsError;

const UserForm = ({
  userObj,
  sendUserFunc,
  cancelFunc,
  isLoading,
  isCreatingNewUser,
  resetPasswordFunc,
  listOfGroups,
}) => {
  const [errorOcurred, setErrorOcurred] = useState(false);
  const setIsError = useStore(isErrorState);

  const onClickSave = () => {
    const form = document.getElementById('user-form');
    const formElements = Array.from(form.elements);

    const editedUserObj = {};
    editedUserObj.user_groups = [];

    formElements.forEach((element) => {
      if (element.type === 'checkbox') {
        if (element.checked) {
          editedUserObj.user_groups.push(element.id);
        }
      } else {
        editedUserObj[element.id] = element.value;
      }
    });

    if (userObj.admin_user) {
      editedUserObj.admin_user = userObj.admin_user;
    }

    if (
      !editedUserObj.first_name ||
      !editedUserObj.last_name ||
      !editedUserObj.email
    ) {
      setIsError('Ops! You forgot to fill out a required field.');
      setErrorOcurred(true);
      if (!editedUserObj.first_name) {
        const firstNameField = document.getElementById('first_name');
        firstNameField.style.borderColor = errorFieldColor;
      }
      if (!editedUserObj.last_name) {
        const lastNameField = document.getElementById('last_name');
        lastNameField.style.borderColor = errorFieldColor;
      }
      if (!editedUserObj.email) {
        const emailField = document.getElementById('email');
        emailField.style.borderColor = errorFieldColor;
      }
      return;
    }

    sendUserFunc(editedUserObj);
  };

  const removeErrors = () => {
    if (errorOcurred) {
      setErrorOcurred(false);
      const firstNameField = document.getElementById('first_name');
      const lastNameField = document.getElementById('last_name');
      const emailField = document.getElementById('email');
      firstNameField.style.borderColor = normalFieldColor;
      lastNameField.style.borderColor = normalFieldColor;
      emailField.style.borderColor = normalFieldColor;
    }
  };

  return (
    <div className={styles.formWrapper}>
      <form id="user-form">
        <label htmlFor="first_name">
          First name*
          <input
            type="text"
            name="first_name"
            id="first_name"
            defaultValue={userObj.first_name}
            onChange={removeErrors}
          />
        </label>
        <label htmlFor="last_name">
          Last name*
          <input
            type="text"
            name="last_name"
            id="last_name"
            defaultValue={userObj.last_name}
            onChange={removeErrors}
          />
        </label>
        <label htmlFor="email">
          E-mail*
          <input
            type="text"
            name="email"
            id="email"
            defaultValue={userObj.email}
            onChange={removeErrors}
          />
        </label>
        <label htmlFor="company">
          Company
          <input
            type="text"
            name="company"
            id="company"
            defaultValue={userObj.company}
            onChange={removeErrors}
          />
        </label>
        <label htmlFor="address">
          Address
          <input
            type="text"
            name="address"
            id="address"
            defaultValue={userObj.address}
            onChange={removeErrors}
          />
        </label>
        <label htmlFor="zipcode">
          Zipcode
          <input
            type="text"
            name="zipcode"
            id="zipcode"
            defaultValue={userObj.zipcode}
            onChange={removeErrors}
          />
        </label>
        <label htmlFor="city">
          City
          <input
            type="text"
            name="city"
            id="city"
            defaultValue={userObj.city}
            onChange={removeErrors}
          />
        </label>
        <label htmlFor="country">
          Country
          <input
            type="text"
            name="country"
            id="country"
            defaultValue={userObj.country}
            onChange={removeErrors}
          />
        </label>
        {listOfGroups?.length > 0 && (
          <div className={styles.groupWrapper}>
            <p>Group</p>
            {listOfGroups.map((group, i) => (
              <label
                key={group.partner_groupid}
                htmlFor={group.partner_groupid}
              >
                {group.display_name}
                <input
                  type="checkbox"
                  id={group.partner_groupid}
                  name={group.partner_groupid}
                  defaultChecked={
                    (isCreatingNewUser && i === 0) ||
                    (!isCreatingNewUser &&
                      userObj?.user_groups.includes(group.partner_groupid))
                  }
                  onChange={() => {}}
                />
              </label>
            ))}
          </div>
        )}
      </form>
      <div className={styles.buttonWrapper}>
        {!isCreatingNewUser && (
          <Button
            className={styles.resetPasswordButton}
            onClick={resetPasswordFunc}
            disabled={isLoading}
          >
            Send recovery link
          </Button>
        )}
        <Button onClick={cancelFunc} disabled={isLoading}>
          Cancel
        </Button>
        <Button onClick={onClickSave} disabled={isLoading}>
          {isCreatingNewUser ? 'Create user' : 'Save'}
        </Button>
      </div>
    </div>
  );
};

UserForm.propTypes = {
  userObj: shape({
    first_name: string,
    last_name: string,
    company: string,
    address: string,
    zipcode: string,
    city: string,
    country: string,
    email: string,
  }),
  sendUserFunc: func.isRequired,
  cancelFunc: func.isRequired,
  resetPasswordFunc: func.isRequired,
  listOfGroups: arrayOf(shape({})),
  isLoading: bool,
  isCreatingNewUser: bool,
};

UserForm.defaultProps = {
  userObj: {
    first_name: '',
    last_name: '',
    company: '',
    address: '',
    zipcode: '',
    city: '',
    country: '',
    email: '',
  },
  listOfGroups: [],
  isLoading: false,
  isCreatingNewUser: false,
};

export default UserForm;
