import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import useStore from 'state/state';
import Button from 'components/elements/Button/Button';
import axios from 'axios';
import EditUserPage from 'components/EditUserPage/EditUserPage';
import UserList from './UserList/UserList';

import styles from './UserListView.module.scss';

const tokenDataState = (state) => [state.tokenData, state.clearGlobalStates];
const userDataState = (state) => [state.userData, state.setUserData];

const UserListView = () => {
  const [tokenData, clearGlobalStates] = useStore(tokenDataState);
  const [userData, setUserData] = useStore(userDataState);
  const [editUserObj, setEditUserObj] = useState(null);

  const config = {
    headers: {
      Authorization: `Bearer ${tokenData}`,
      'x-api-key': process.env.API_KEY,
      'X-Origin': window.origin,
    },
  };

  const logout = () => {
    axios
      .get(`${process.env.URL}/user/logout`, config)
      .then(() => {
        clearGlobalStates();
        sessionStorage.removeItem('token');
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchUserData = () => {
    axios
      .get(`${process.env.URL}/user/details`, config)
      .then((res) => {
        setUserData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (!userData) {
      fetchUserData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (editUserObj) {
    return (
      <EditUserPage editUserObj={editUserObj} setEditUserObj={setEditUserObj} />
    );
  }

  return (
    <div className={styles.userWrapper}>
      <div className={styles.buttonWrapper}>
        {userData && userData.first_name ? (
          <p>
            {`User: ${userData.first_name}`}
            {userData.last_name ? ` ${userData.last_name}` : ''}
          </p>
        ) : (
          <p />
        )}
        <Link to="/new-user">
          <Button onClick={() => {}}>Create a new user</Button>
        </Link>
        <Button onClick={logout}>Logout</Button>
      </div>
      <UserList setEditUserObj={setEditUserObj} />
    </div>
  );
};

export default UserListView;
