import React from 'react';
import { Link } from 'react-router-dom';

import styles from './NotFound.module.scss';

const NotFound = () => (
  <div className={styles.wrapper}>
    <h2 className={styles.heading}>Page not found</h2>
    <Link to="/">Take me home</Link>
  </div>
);

export default NotFound;
