import React from 'react';
import { node, func, bool, string } from 'prop-types';
import clsx from 'clsx';

import styles from './Button.module.scss';

const Button = ({ children, onClick, disabled, className }) => (
  <button
    type="button"
    onClick={onClick}
    disabled={disabled}
    className={clsx(styles.button, className)}
  >
    {children && children}
  </button>
);

Button.propTypes = {
  children: node,
  onClick: func.isRequired,
  disabled: bool,
  className: string,
};

Button.defaultProps = {
  children: null,
  disabled: false,
  className: null,
};

export default Button;
