import React, { useEffect, useState } from 'react';
import useStore from 'state/state';
import axios from 'axios';
import UserListView from 'components/UserListView/UserListView';
import Button from 'components/elements/Button/Button';

import styles from './Home.module.scss';

const normalFieldColor = '#ededeb';
const errorFieldColor = '#c63d11';

const tokenDataState = (state) => [state.tokenData, state.setTokenData];
const isErrorState = (state) => state.setIsError;

const Home = () => {
  const [tokenData, setTokenData] = useStore(tokenDataState);
  const [isLoading, setIsLoading] = useState(false);
  const setIsError = useStore(isErrorState);
  const [errorOcurred, setErrorOcurred] = useState(false);

  const onClickLogin = () => {
    const usernameField = document.getElementById('username');
    const passwordField = document.getElementById('password');

    let isError = false;
    if (!usernameField.value || !passwordField.value) {
      setIsError('Ops! You forgot to fill out all the fields.');
      setErrorOcurred(true);
      isError = true;
      if (!usernameField.value) {
        usernameField.style.borderColor = errorFieldColor;
      }
      if (!passwordField.value) {
        passwordField.style.borderColor = errorFieldColor;
      }
    }
    if (isError) {
      return;
    }
    setIsLoading(true);
    const config = {
      headers: {
        'x-api-key': process.env.API_KEY,
        'X-Origin': window.origin,
      },
    };
    const data = {
      email: usernameField.value,
      password: passwordField.value,
    };

    axios
      .post(`${process.env.URL}/user/loginAdmin`, JSON.stringify(data), config)
      .then((res) => {
        if (res.data.token) {
          setTokenData(res.data.token);
          sessionStorage.setItem('token', res.data.token);
        } else {
          setIsError('Something went wrong!');
        }
        setIsLoading(false);
      })
      .catch((error) => {
        if (error.response.data) {
          const errorMessage = error.response.data.error.message;
          if (errorMessage === 'Unknown user') {
            usernameField.style.borderColor = errorFieldColor;
            setIsError('Wrong username.');
            setErrorOcurred(true);
          } else if (errorMessage === 'Password missmatch') {
            passwordField.style.borderColor = errorFieldColor;
            setIsError('Wrong password.');
            setErrorOcurred(true);
          } else {
            setIsError('Something went wrong!');
          }
        } else {
          setIsError('Something went wrong!');
        }
        setIsLoading(false);
      });
  };

  const onKeyDown = (e) => {
    if (e.code === 'Enter') {
      onClickLogin();
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown);

    return () => {
      document.removeEventListener('keydown', onKeyDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const removeErrors = () => {
    if (errorOcurred) {
      setErrorOcurred(false);
      const usernameField = document.getElementById('username');
      const passwordField = document.getElementById('password');
      usernameField.style.borderColor = normalFieldColor;
      passwordField.style.borderColor = normalFieldColor;
    }
  };

  return (
    <div className={styles.wrapper}>
      {tokenData ? (
        <UserListView />
      ) : (
        <>
          <h3>Exeger User Management</h3>
          <div className={styles.loginWrapper}>
            <form>
              <label htmlFor="username">
                Username:
                <input
                  type="text"
                  name="username"
                  id="username"
                  onChange={removeErrors}
                />
              </label>
              <label htmlFor="password">
                Password:
                <input
                  type="password"
                  name="password"
                  id="password"
                  onChange={removeErrors}
                />
              </label>
            </form>
            <Button onClick={onClickLogin} disabled={isLoading}>
              Login
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default Home;
