import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import { render } from 'react-dom';
import { hot } from 'react-hot-loader/root';
import { BrowserRouter } from 'react-router-dom';

import App from 'components/App/App';

require('styles/index.scss');

const renderHotHash = (Component) => {
  render(
    <BrowserRouter>
      <Component />
    </BrowserRouter>,
    document.getElementById('app')
  );
};

renderHotHash(hot(App));
