import React from 'react';
import { string, func } from 'prop-types';
import styles from './SortingButton.module.scss';

const SortingButton = ({
  onClick,
  children,
  field,
  currentField,
  currentOrder,
}) => (
  <button type="button" onClick={onClick} className={styles.sortingButton}>
    {children}

    {field === currentField ? (
      <span>{currentOrder === 'asc' ? '\u2191' : '\u2193'}</span>
    ) : (
      <span>{'\u2195'}</span>
    )}
  </button>
);

SortingButton.propTypes = {
  field: string.isRequired,
  currentField: string,
  currentOrder: string,
  onClick: func.isRequired,
  children: string.isRequired,
};

SortingButton.defaultProps = {
  currentField: null,
  currentOrder: 'asc',
};

export default SortingButton;
