import React, { useState } from 'react';
import { shape, string, func } from 'prop-types';
import axios from 'axios';
import useStore from 'state/state';
import UserForm from 'components/elements/UserForm/UserForm';

import styles from './EditUserPage.module.scss';

const tokenDataState = (state) => state.tokenData;
const isErrorState = (state) => state.setIsError;
const isInfoMessageState = (state) => state.setIsInfoMessage;
const insertUpdatedUserState = (state) => state.insertUpdatedUser;
const listOfGroupsState = (state) => state.listOfGroups;

const EditUserPage = ({ editUserObj, setEditUserObj }) => {
  const [isLoading, setIsLoading] = useState(false);
  const tokenData = useStore(tokenDataState);
  const setIsError = useStore(isErrorState);
  const setIsInfoMessage = useStore(isInfoMessageState);
  const insertUpdatedUser = useStore(insertUpdatedUserState);
  const listOfGroups = useStore(listOfGroupsState);

  const onSend = (obj) => {
    const updatedUser = {
      ...obj,
      userid: editUserObj.userid,
      // user_groups: editUserObj.user_groups,
    };

    setIsLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${tokenData}`,
        'x-api-key': process.env.API_KEY,
        'X-Origin': window.origin,
      },
    };

    axios
      .post(`${process.env.URL}/user/update`, updatedUser, config)
      .then(() => {
        insertUpdatedUser(updatedUser);
        setIsLoading(false);
        setEditUserObj(null);
      })
      .catch((error) => {
        console.log('err', error);
        setIsError('Something went wrong.');
        setIsLoading(false);
      });
  };

  const onCancel = () => {
    setEditUserObj(null);
  };

  const onResetPassword = () => {
    const data = {
      email: editUserObj.email,
      url: process.env.PASSWORD_RESET_URL,
    };

    const config = {
      headers: {
        Authorization: `Bearer ${tokenData}`,
        'x-api-key': process.env.API_KEY,
        'X-Origin': window.origin,
      },
    };

    setIsLoading(true);
    axios
      .post(`${process.env.URL}/email/resetPassword`, data, config)
      .then(() => {
        setIsLoading(false);
        setIsInfoMessage(
          `A verification email has been sent to ${editUserObj.email}`
        );
      })
      .catch((error) => {
        console.log('err', error);
        setIsError('Something went wrong');
        setIsLoading(false);
      });
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.contentWrapper}>
        <h3>Edit user</h3>
        <UserForm
          userObj={editUserObj}
          sendUserFunc={onSend}
          cancelFunc={onCancel}
          resetPasswordFunc={onResetPassword}
          isLoading={isLoading}
          listOfGroups={listOfGroups}
        />
      </div>
    </div>
  );
};
EditUserPage.propTypes = {
  editUserObj: shape({
    first_name: string,
    last_name: string,
    company: string,
    address: string,
    zipcode: string,
    city: string,
    country: string,
    email: string,
  }).isRequired,
  setEditUserObj: func.isRequired,
};

export default EditUserPage;
