/* eslint-disable camelcase */
import React, { useEffect, useMemo, useState } from 'react';
import useStore from 'state/state';
import { func } from 'prop-types';
import axios from 'axios';

import DeleteUserModal from 'components/DeleteUserModal/DeleteUserModal';
import Button from 'components/elements/Button/Button';
import Loader from 'components/elements/Loader/Loader';
import styles from './UserList.module.scss';
import SortingButton from './SortingButton/SortingButton';

const tokenDataState = (state) => state.tokenData;
const isErrorState = (state) => state.setIsError;
const listOfUsersState = (state) => [state.listOfUsers, state.setListOfUsers];
const listOfGroupsState = (state) => [
  state.listOfGroups,
  state.setListOfGroups,
];
const addGroupToUserState = (state) => state.addGroupToUser;
const removeGroupFromUserState = (state) => state.removeGroupFromUser;
const userListSortingState = (state) => [
  state.userListSorting,
  state.setUserListSorting,
];

const userListRoleFilterState = (state) => [
  state.userListRoleFilter,
  state.setUserListRoleFilter,
];

const UserList = ({ setEditUserObj }) => {
  const tokenData = useStore(tokenDataState);
  const setIsError = useStore(isErrorState);
  const [listOfUsers, setListOfUsers] = useStore(listOfUsersState);
  const [listOfGroups, setListOfGroups] = useStore(listOfGroupsState);
  const [userToDelete, setUserToDelete] = useState(null);
  const addGroupToUser = useStore(addGroupToUserState);
  const removeGroupFromUser = useStore(removeGroupFromUserState);
  const [userListSorting, setUserListSorting] = useStore(userListSortingState);
  const [userListRoleFilter, setUserListRoleFilter] = useStore(
    userListRoleFilterState
  );
  const setIsModalOpen = useStore((state) => state.setIsModalOpen);

  const config = {
    headers: {
      Authorization: `Bearer ${tokenData}`,
      'x-api-key': process.env.API_KEY,
      'X-Origin': window.origin,
    },
  };

  const fetchUserList = () => {
    axios
      .get(`${process.env.URL}/user/list`, config)
      .then((res) => {
        setListOfUsers(res.data);
      })
      .catch((err) => {
        console.log(err);
        setIsError('Something went wrong, user list was not collected.');
      });
  };

  const fetchGroups = () => {
    axios
      .get(`${process.env.URL}/group/list`, config)
      .then((res) => {
        const groups = [];
        res.data.map((gr) => {
          const display_name = gr.group_name.split(' - ');
          return groups.push({ ...gr, display_name: display_name[1] });
        });
        setListOfGroups(groups);
      })
      .catch((err) => {
        console.log(err);
        setIsError('Something went wrong, user groups was not collected.');
      });
  };

  useEffect(() => {
    if (!listOfUsers) {
      fetchUserList();
    }
    if (!listOfGroups) {
      fetchGroups();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filteredListOfUsers = useMemo(() => {
    if (!userListRoleFilter) {
      return listOfUsers;
    }

    return (listOfUsers || []).filter((user) => {
      if (userListRoleFilter === 'administrators') {
        return user.admin_user === '1';
      }

      return user.user_groups.includes(userListRoleFilter);
    });
  }, [listOfUsers, userListRoleFilter]);

  const sortedListOfUsers = useMemo(() => {
    if (!userListSorting.field) {
      return filteredListOfUsers;
    }

    const returnSortingType = (val) => {
      if (typeof val === 'string') {
        return val.toUpperCase().trim();
      }
      if (val === null) {
        return '';
      }
      return val;
    };

    const users = [...(filteredListOfUsers || [])];
    return users.sort((a, b) => {
      const paramA = returnSortingType(a[userListSorting.field]);
      const paramB = returnSortingType(b[userListSorting.field]);
      if (userListSorting.order === 'asc') {
        return paramA < paramB ? -1 : 1;
      }

      return paramA < paramB ? 1 : -1;
    });
  }, [filteredListOfUsers, userListSorting.field, userListSorting.order]);

  const sortByField = (field) => {
    let tempSort = { ...userListSorting };

    // No userListSorting been done before (1st click)
    if (!userListSorting.field) {
      tempSort = {
        ...tempSort,
        field,
      };
    } else if (userListSorting.field && userListSorting.field === field) {
      // Sorting has been done before, change to desc (2nd click)
      if (userListSorting.order === 'asc') {
        tempSort = {
          ...tempSort,
          order: 'desc',
        };
      } else {
        // Reset to no sort (3rd click)
        tempSort = {
          field: null,
          order: 'asc',
        };
      }
    } else {
      // Table has been sorted, but sorting changed to a new column
      // Sort by this field asc (same as 1st click)
      tempSort = {
        field,
        order: 'asc',
      };
    }

    setUserListSorting(tempSort);
  };

  const filterRole = (role) => {
    setUserListRoleFilter(role);
  };

  const onClickCheckbox = (e, userid, groupid) => {
    const isAddingToGroup = e.target.checked;
    e.target.disabled = true;
    const url = isAddingToGroup
      ? `${process.env.URL}/group/addUser`
      : `${process.env.URL}/group/removeUser`;
    const data = {
      userid,
      groupid,
    };
    axios
      .post(url, data, config)
      .then(() => {
        const index = listOfUsers.findIndex((el) => el.userid === userid);
        if (isAddingToGroup) {
          addGroupToUser(index, String(groupid));
        } else {
          removeGroupFromUser(index, String(groupid));
        }
        e.target.disabled = false;
      })
      .catch((error) => {
        console.log(error);
        e.target.disabled = false;
        setIsError('Something went wrong, could not update the user.');
      });
  };

  return (
    <div className={styles.userListWrapper}>
      {userToDelete && <DeleteUserModal user={userToDelete} />}
      {sortedListOfUsers && listOfGroups ? (
        <ul className={styles.userList}>
          <li className={styles.header}>
            <p>
              <SortingButton
                field="first_name"
                currentField={userListSorting.field}
                currentOrder={userListSorting.order}
                onClick={() => sortByField('first_name')}
              >
                First name
              </SortingButton>
            </p>
            <p>
              <SortingButton
                field="last_name"
                currentField={userListSorting.field}
                currentOrder={userListSorting.order}
                onClick={() => sortByField('last_name')}
              >
                Last name
              </SortingButton>
            </p>
            <p>
              <SortingButton
                field="company"
                currentField={userListSorting.field}
                currentOrder={userListSorting.order}
                onClick={() => sortByField('company')}
              >
                Company
              </SortingButton>
            </p>
            <p className={styles.email}>E-mail</p>
            <div>
              <span>Group</span>
            </div>
            <p className={styles.filterWrapper}>
              <select
                onChange={(e) => filterRole(e.currentTarget.value)}
                value={userListRoleFilter}
              >
                <option value="">All users</option>
                <option value="administrators">Administrators</option>
                {listOfGroups.map((group) => (
                  <option
                    key={group.partner_groupid}
                    value={group.partner_groupid}
                  >
                    {group.display_name}
                  </option>
                ))}
              </select>
            </p>
          </li>
          {sortedListOfUsers.map((u) => (
            <li key={u.email}>
              <p>{u.first_name}</p>
              <p>{u.last_name}</p>
              <p>{u.company}</p>
              <p className={styles.email}>{u.email}</p>
              <div>
                {listOfGroups.map((group) => (
                  <label
                    key={u.user_groups.length + group.partner_groupid}
                    htmlFor={group.partner_groupid}
                  >
                    {group.display_name}
                    <input
                      type="checkbox"
                      id={group.partner_groupid}
                      name={group.partner_groupid}
                      checked={u.user_groups.includes(group.partner_groupid)}
                      onChange={(e) => {
                        onClickCheckbox(e, u.userid, group.partner_groupid);
                      }}
                    />
                  </label>
                ))}
              </div>
              <Button
                onClick={() => {
                  setEditUserObj(u);
                }}
              >
                Edit
              </Button>
              {!u.admin_user || u.admin_user === '0' ? (
                <>
                  <Button
                    onClick={() => {
                      setUserToDelete(u);
                      setIsModalOpen();
                    }}
                  >
                    Delete user
                  </Button>
                </>
              ) : (
                <p className={styles.adminText}>Administrator</p>
              )}
            </li>
          ))}
        </ul>
      ) : (
        <Loader />
      )}
    </div>
  );
};

UserList.propTypes = {
  setEditUserObj: func.isRequired,
};

export default UserList;
