/* eslint-disable react/no-unescaped-entities */
import React, { useState } from 'react';
import { animated, useTransition } from 'react-spring';
import axios from 'axios';
import useStore from 'state/state';

import Button from 'components/elements/Button/Button';

import styles from './DeleteUserModal.module.scss';

const tokenDataState = (state) => state.tokenData;
const isModalState = (state) => [state.isModalOpen, state.setIsModalOpen];
const isErrorState = (state) => state.setIsError;
const listOfUsersState = (state) => [state.listOfUsers, state.setListOfUsers];

const DeleteUserModal = ({ user }) => {
  const [isModalOpen, setIsModalOpen] = useStore(isModalState);
  const tokenData = useStore(tokenDataState);
  const setIsError = useStore(isErrorState);
  const [listOfUsers, setListOfUsers] = useStore(listOfUsersState);
  const [isLoading, setIsLoading] = useState(false);

  const transition = useTransition(isModalOpen, null, {
    from: { opacity: 0, top: '48%' },
    enter: { opacity: 1, top: '50%' },
    leave: { opacity: 0 },
  });

  const onClickDelete = () => {
    setIsLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${tokenData}`,
        'x-api-key': process.env.API_KEY,
        'X-Origin': window.origin,
      },
    };

    axios
      .post(`${process.env.URL}/user/delete`, { id: user.userid }, config)
      .then((res) => {
        if (res.data) {
          const newUserList = [...listOfUsers];
          const filteredNewList = newUserList.filter(
            (u) => u.userid !== user.userid
          );
          setListOfUsers(filteredNewList);
          setIsLoading(false);
          setIsModalOpen();
        } else {
          setIsError('Something went wrong when we tried to delete the user.');
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsError('Something went wrong when we tried to delete the user.');
        setIsLoading(false);
      });
  };

  return transition.map(
    ({ item, key, props }) =>
      item && (
        <div className={styles.overlay} key="overlay">
          <animated.div className={styles.wrapper} key={key} style={props}>
            <div>
              <p className={styles.paragraph}>
                Are you sure you want to delete the user
              </p>
              <p className={styles.paragraph}>
                {`${user.first_name} ${user.last_name}?`}
              </p>
            </div>
            <div className={styles.buttonWrapper}>
              <Button disabled={isLoading} onClick={onClickDelete}>
                Yes, delete user
              </Button>
              <Button disabled={isLoading} onClick={setIsModalOpen}>
                Cancel
              </Button>
            </div>
          </animated.div>
        </div>
      )
  );
};

export default DeleteUserModal;
